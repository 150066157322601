/** @jsx jsx */
import * as React from 'react';
import { jsx, Box } from 'theme-ui';
import { Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import { FixedObject } from 'gatsby-image';
import ItemTags from '@lekoarts/gatsby-theme-minimal-blog/src/components/item-tags';

type BlogListItemProps = {
  post: {
    slug: string;
    title: string;
    date: string;
    excerpt: string;
    description: string;
    banner?: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    timeToRead?: number;
    tags?: {
      name: string;
      slug: string;
    }[];
  };
  showTags?: boolean;
};

const BlogListItemBanner = ({ post, showTags = true }: BlogListItemProps) => (
  <Box mb={4} className="justify-center content-center text-center">
    <br></br>
    <Link
      to={post.slug}
      sx={(t) => ({ ...t.styles?.a, fontSize: [2, 4, 5], color: `text` })}
    >
      <Img
        fluid={post.banner?.childImageSharp?.fluid}
        className="justify-center"
      />
      {post.title}
    </Link>
    <p
      sx={{
        color: `secondary`,
        mt: 1,
        a: { color: `secondary` },
        fontSize: [1, 2, 4],
      }}
    >
      <time>{post.date}</time>
      {post.tags && showTags && (
        <React.Fragment>
          {` — `}
          <ItemTags tags={post.tags} />
        </React.Fragment>
      )}
    </p>
  </Box>
);

export default BlogListItemBanner;
