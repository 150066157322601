/** @jsx jsx */
import { jsx } from 'theme-ui';
import BlogListItem from '@lekoarts/gatsby-theme-minimal-blog/src/components/blog-list-item';
import BlogListItemBanner from './blog-list-item-banner';
import { FixedObject } from 'gatsby-image';

type ListingProps = {
  posts: {
    slug: string;
    title: string;
    date: string;
    excerpt: string;
    description: string;
    banner?: {
      childImageSharp: {
        resize: {
          src: string;
        };
      };
    };
    timeToRead?: number;
    tags?: {
      name: string;
      slug: string;
    }[];
  }[];
  className?: string;
  showTags?: boolean;
};

const ListingBanner = ({
  posts,
  className = `container mx-auto`,
  showTags = true,
}: ListingProps) => (
  <section sx={{ mb: [5, 6, 7] }} className={className}>
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
      {posts.map((post) => (
        <BlogListItemBanner key={post.slug} post={post} showTags={showTags} />
      ))}
    </div>
  </section>
);

export default ListingBanner;
