import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div style={{
      "alignItems": "center",
      "margin": "auto",
      "display": "flex",
      "flexDirection": "column",
      "maxWidth": "500px",
      "textAlign": "center"
    }}>
      <img src='./qrcode.png' />
      <br></br>
      <Text sx={{
        fontSize: [1, 2]
      }} mdxType="Text">
    cat tienvv.blog | grep -e 'LOVE' -e 'LIFE' -e 'TECH'
      </Text>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      