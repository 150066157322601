import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Contact me" mdxType="Title" />
    <ul>
      <li parentName="ul">{`For fulltime job, contact me via `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/tienvu461"
        }}>{`Linkedin`}</a></li>
      <li parentName="ul">{`For freelancer jobs find me on
`}<a parentName="li" {...{
          "href": "https://discordapp.com/users/tienvv#6304"
        }}>{`Discord`}</a>{` or
`}<a parentName="li" {...{
          "href": "live:tienvu461"
        }}>{`Skype`}</a>{` or `}<a parentName="li" {...{
          "href": "https://github.com/tienvu461"
        }}>{`GitHub`}</a></li>
      <li parentName="ul">{`For other funny stuff, mail me `}<a parentName="li" {...{
          "href": "mailto:tienvu461@gmail.com"
        }}>{`tienvu461@gmail.com`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      